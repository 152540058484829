import React, { useEffect, useState } from 'react'
import BasicTable from 'components/BasicTable'
import FormSearch from './Partials/FormSearch'
import Button from 'components/form/Button'
import styles from '../../../styles/Search.module.css'
import style from '../../../styles/UserTabs.module.css'
import proofStyle from '../../../styles/Proof.module.css'

import { points } from 'data/users'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllInfractions } from 'store/modules/moderation/infractions/allInfractionsSlice'
import { toast } from 'react-toastify'
import { reverseInfraction } from 'services/infractionService'
import { InfinitySpin } from 'react-loader-spinner'
import Tabs from 'components/Tabs'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { getAvatars } from 'services/avatarsService'
import { deleteChatLogProofList, deleteTransactionProofList, saveProofList } from 'services/abuseReportService'
import { ChatMessage } from 'Pages/Spaces/Partials/chatMessage'
import { Paths } from 'paths/Paths'
import Modal from 'components/Modal'
import moment from 'moment'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import { saveUser } from 'services/userService'
import { getChatLog } from 'services/chatLogService'
import { getTransactions } from 'services/transactionService'

export default function Infractions({ userId, infractionId, punishmentId, dateFrom, dateTo }) {
    const verifyInfractions = useUserPermission(SMIPermissionsCode.MOD_VIEW_INFRACTIONS);
    const verifyInfractionPunishments = useUserPermission(SMIPermissionsCode.MOD_SEE_INFRACTION_PUNISHMENTS);
    const verifyReverseInfraction = useUserPermission(SMIPermissionsCode.MOD_REVERSE_INFRACTIONS);
    const verifyUserNotes = useUserPermission(SMIPermissionsCode.MOD_USER_NOTES_VIEW);
    const verifyUserNotesEdit = useUserPermission(SMIPermissionsCode.MOD_CAN_REMOVE_AUTHENTICATOR);

    const [infractionsHeaders, setInfractionsHeaders] = useState(verifyInfractionPunishments ? [
        { title: "Data", key: "date", appear: true },
        { title: "Usuário", key: "userName", copy: "userId", appear: true },
        { title: "Infração", key: "offence", appear: true },
        { title: "Punição", key: "punishment", appear: true },
        { title: 'Provas', key: 'isProofList', selectable: true, appear: true },
        { title: "Moderador", key: "modName", copy: "modId", appear: true },
        { title: "Ativo", key: "active", appear: true },
    ] : [
        { title: "Data", key: "date", appear: true },
        { title: "Usuário", key: "userName", copy: "userId", appear: true },
        { title: "Infração", key: "offence", appear: true },
        { title: 'Provas', key: 'isProofList', selectable: true, appear: true },
        { title: "Moderador", key: "modName", copy: "modId", appear: true },
        { title: "Ativo", key: "active", appear: true },
    ])

    const [infractionsMomentHeaders, setInfractionsMomentHeaders] = useState([...infractionsHeaders])

    const handleAppearHeader = (item) => {
        setInfractionsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setInfractionsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }

    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const allInfractions = useSelector((state) => {
        return Array.isArray(state.allInfractions.data) ? state.allInfractions.data : []
    })

    const skip = useSelector((state) => {
        return (state.allInfractions.currentSkip) ? state.allInfractions.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.allInfractions.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.allInfractions.filters) ? state.allInfractions.filters : {}
    })

    console.log(filters)

    useEffect(() => {
        setLoading(false)
    }, [allInfractions]);

    const dispatch = useDispatch()


    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (userId || infractionId || punishmentId || dateFrom || dateTo) {
            setLoading(true)
            fetchAllInfractions(dispatch, { userId, infractionId, punishmentId, dateFrom, dateTo }, 0);
        }
    }


    const search = () => {
        setLoading(true)

        const query = new URLSearchParams();
        if (filter.form.userId) query.append('userId', filter.form.userId);
        if (filter.form.infractionId) query.append('infractionId', filter.form.infractionId);
        if (filter.form.punishmentId) query.append('punishmentId', filter.form.punishmentId);
        if (filter.form.dateFrom) query.append('dateFrom', filter.form.dateFrom);
        if (filter.form.dateTo) query.append('dateTo', filter.form.dateTo);
        window.history.pushState({}, '', `/moderation/infractions?${query.toString()}`);

        fetchAllInfractions(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchAllInfractions(dispatch, filter.form, skip + 200);
    })

    const handleReverseInfraction = async (item) => {
        console.log(item)
        try {
            await reverseInfraction(item.userInfractionId);


            search()

            toast.success('Infração Desativada com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Desativar Infração!');
        }

    }

    const [isProofListModalOpen, setIsProofListModalOpen] = useState(false);
    const [actualProofList, setActualProofList] = useState([]);
    const [actualAbuseReportId, setActualAbuseReportId] = useState([]);
    const [transactionsProofList, setTransactionsProofList] = useState([]);
    const [avatarInfos, setAvatarInfos] = useState([]);
    const [isProofLoading, setProofLoading] = useState(false);

    const [originalReportedModNotes, setOriginalReportedModNotes] = useState('')
    const [reportedModNotes, setReportedModNotes] = useState('')
    const [reportedUserId, setReportedUserId] = useState('')
    const [isReportedModNotesChange, setIsReportedModNotesChange] = useState(true)

    const transactionsHeaders = [
        { title: 'Tipo', key: 'type', copy: 'type' },
        { title: 'Remetente', key: 'sender', copy: 'senderId' },
        { title: 'Destinatário', key: 'receiver', copy: 'receiverId' },
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' /> },
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' /> },
        { title: 'Detalhes', key: 'details' },
        { title: 'Data', key: 'timestamp' },
    ]

    const handleOpenProofListModal = () => {
        setIsProofListModalOpen(true);
    }

    const handleCloseProofListModal = () => {
        setActualAbuseReportId('');
        setReportedModNotes('')
        setOriginalReportedModNotes('')
        setReportedUserId('')
        setIsProofListModalOpen(false);
    }

    const handleOnChangeReportedModNotes = (e) => {
        setReportedModNotes(e.target.value);
    }

    useEffect(() => {
        if (originalReportedModNotes != reportedModNotes) {
            setIsReportedModNotesChange(false)
        }
        else {
            setIsReportedModNotesChange(true)
        }
    }, [reportedModNotes])

    const handleDiscardReportedModNotesChanges = () => {
        setReportedModNotes(originalReportedModNotes)
        setIsReportedModNotesChange(true)

    }

    const handleSaveReportedModNotesChanges = async () => {
        try {
            let changes = {}
            if (originalReportedModNotes !== reportedModNotes) {
                changes['modNotes'] = reportedModNotes
                /*
                let newModNoteProof = {
                    type: "modNote",
                    timestamp: new Date(),
                    abuseReportId: abuseReportIds[0],
                    data: {
                        note: reportedModNotes,
                        userId: reportedUserId
                    },
                };
        
                let tempList = [];
                tempList.push(newModNoteProof);
                await saveProofList(tempList);
                */
            }

            console.log(reportedUserId, changes)
            await saveUser(reportedUserId, changes)
            await fetchAllInfractions(dispatch, filter.form, 0);

            setReportedModNotes(reportedModNotes)
            setOriginalReportedModNotes(reportedModNotes)
            setIsReportedModNotesChange(true)

            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }
    }


    const handleSelect = (item, cell) => {
        if (cell.key == 'isProofList' && item.isProofList == 'Sim') {
            console.log(item)
            setActualAbuseReportId(item.abuseReportId)
            setProofLoading(true)
            setActualProofList(item.proofList);
            handleProofListAvatars(item.proofList)
            handleProofListTransactions(item.proofList)
            setOriginalReportedModNotes(item.reportedModNotes)
            setReportedModNotes(item.reportedModNotes)
            setReportedUserId(item.reportedUserId)
            handleOpenProofListModal();
        }
    }

    const handleProofListAvatars = async (list) => {
        let avatarInfos = []
        list.map((item, i) => {
            if (item.type == 'chatLog') {
                avatarInfos.push({ avatarId: item.data.avatarId, message: item.data.message, proofId: item._id, messageDate: item.data.timestamp })
            }
        })

        for (let i = 0; i < avatarInfos.length; i++) {
            let actualAvatar = await getAvatars({ avatarId: avatarInfos[i].avatarId }, 0);
            avatarInfos[i] = {
                avatarId: avatarInfos[i].avatarId,
                avatarName: actualAvatar[0].avatarName,
                headPostfix: actualAvatar[0].headPostfix,
                message: avatarInfos[i].message,
                proofId: avatarInfos[i].proofId,
                messageDate: avatarInfos[i].messageDate
            }
        }
        console.log(avatarInfos)
        setAvatarInfos(avatarInfos)
        setProofLoading(false)
    }

    const handleDeleteChatLogProof = async (proofId) => {
        try {
            await deleteChatLogProofList(actualAbuseReportId, proofId);
            let proofArr = [...actualProofList];


            actualProofList.map((item, i) => {
                if (item._id == proofId) {
                    proofArr.splice(proofArr.indexOf(item), 1);
                }
            })
            console.log(proofArr)
            setActualProofList(proofArr)
            handleProofListAvatars(proofArr)


            search()

            if (proofArr.length == 0) {
                handleCloseProofListModal()
            }

            toast.success('Prova(s) Deletada(s) com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Deletar Prova(s)!');
        }
    }

    const handleProofListTransactions = async (list) => {
        let transactionItems = []
        list.map((item, i) => {
            if (item.type == 'transaction') {
                transactionItems.push({
                    type: item.data.type,
                    sender: item.data.senderName,
                    senderId: item.data.senderId,
                    receiverId: item.data.receiverId,
                    receiver: item.data.receiverName,
                    gold: item.data.gold,
                    tokens: item.data.tokens,
                    details: item.data.details,
                    timestamp: moment(item.data.timestamp, "YYYY-MM-DDTHH:mm:ss:ss.SSSZ").format("DD/MM/YYYY HH:mm:ss"),
                    proofId: item._id
                });
            }
        })
        setTransactionsProofList(transactionItems)
    }


    const handleDeleteTransactionProof = async (tableItem) => {
        try {
            await deleteTransactionProofList(actualAbuseReportId, tableItem.proofId);
            let proofArr = [...actualProofList];


            actualProofList.map((item, i) => {
                if (item._id == tableItem.proofId) {
                    proofArr.splice(proofArr.indexOf(item), 1);
                }
            })
            console.log(proofArr)
            setActualProofList(proofArr)

            handleProofListTransactions(proofArr)


            search()

            if (proofArr.length == 0) {
                handleCloseProofListModal()
            }

            toast.success('Prova(s) Deletada(s) com Sucesso!');
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Deletar Prova(s)!');
        }

    }

    const proofListTabs = [
        {
            id: 1,
            tabTitle: 'ChatLog',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.infoContainer}>
                            {
                                avatarInfos.map((item, i) => {
                                    return (
                                        <div className={proofStyle.manageChatLog}>
                                            <Button disabled={false} text="Excluir" onClick={() => handleDeleteChatLogProof(item.proofId)} color="cyan" />
                                            <div className={proofStyle.message}>
                                                <div className={proofStyle.messageContent}>
                                                    <ChatMessage key={i} avatarIcon={Paths.getAvatarHeadURL(item.avatarId, item.headPostfix)} avatarFirstName={item.avatarName} message={item.message} />
                                                </div>
                                                <div className={proofStyle.messageDate}>{moment(item.messageDate, "YYYY-MM-DDTHH:mm:ss:ss.SSSZ").format("DD/MM/YYYY HH:mm:ss")}</div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Transação',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.transactionInfoContainer}>
                            <BasicTable
                                onClickBtnItemInfo={'Excluir'}
                                hasButton={true}
                                onClickBtnItem={handleDeleteTransactionProof}
                                onClickBtnItemHeader={'Delete'}
                                height='33vh'
                                headers={transactionsHeaders}
                                title='Transações'
                                items={transactionsProofList}
                                slotActions={<></>}
                            />
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 3,
            tabTitle: 'Notas',
            content: (
                <div className={proofStyle.container}>
                    <div className="mt-2 p-2">
                        <div className={style.modNotesHeader}>
                            {(isReportedModNotesChange) ? "" :
                                <div className={style.header}>
                                    <Button text="Salvar" onClick={handleSaveReportedModNotesChanges} color="cyan" />
                                    <Button text="Descartar" onClick={handleDiscardReportedModNotesChanges} color="cyan" />
                                </div>
                            }
                        </div>
                        {<textarea rows="4" value={reportedModNotes} onChange={verifyUserNotesEdit ? handleOnChangeReportedModNotes : () => { }} name="comment" id="comment" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[33vh]"></textarea>}
                    </div>
                </div>
            )
        }
    ]

    //add prova

    const [abuseReportIds, setAbuseReportIds] = useState([]);
    const [abuseReportsContent, setAbuseReportsContent] = useState([]);
    const [chatLogIdInputValue, setChatLogIdInputValue] = useState('');
    const [transactionIdInputValue, setTransactionIdInputValue] = useState('');
    const [proofList, setProofList] = useState([]);
    const [transactionsProof, setTransactionsProof] = useState([]);
    const [isProofModalOpen, setIsProofModalOpen] = useState(false);

    const handleOnChangeChatLogIdInput = (e) => {
        setChatLogIdInputValue(e.target.value);
    }

    const handleOnChangeTransactionIdInput = (e) => {
        setTransactionIdInputValue(e.target.value);
    }

    const handleCloseProofModal = () => {
        setIsProofModalOpen(false);
        setTransactionIdInputValue('');
        setChatLogIdInputValue('');
        setProofList([]);
        setActualAbuseReportId('')
        setTransactionsProof([]);
        setAbuseReportIds([])

        setOriginalReportedModNotes('')
        setReportedModNotes('')
        setReportedUserId('')
        setIsReportedModNotesChange(true)
    }

    const handleAddChatLogProof = async () => {
        try {
            const msg = await getChatLog({ id: chatLogIdInputValue });
            setChatLogIdInputValue('');
            if (!msg.hasOwnProperty("messageId")) {
                toast.error('Erro ao Adicionar ChatLog!')
            }
            else {
                let newMsgProof = {
                    abuseReportId: abuseReportIds[0],
                    msgId: msg.messageId,
                    headUrl: Paths.getAvatarHeadURL(msg.avatarId, msg.headPostfix),
                    avatarFullName: msg.avatarName,
                    type: "chatLog",
                    timestamp: new Date(),
                    data: {
                        id: msg.avatarId,
                        message: msg.message,
                        timestamp: moment(msg.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss:ss.SSSZ")
                    },
                };

                let verifyExistence = false
                let tempList = [...proofList];
                tempList.map((item) => {
                    if (item.type == 'chatLog') {
                        if (item.msgId == newMsgProof.msgId) {
                            verifyExistence = true
                        }
                    }
                })
                if (!verifyExistence) {
                    tempList.push(newMsgProof);
                }
                else {
                    toast.error('ChatLog já adicionado!')
                }
                setProofList(tempList);
            }
        }
        catch {
            toast.error('Erro ao Adicionar ChatLog!')
        }
    }

    const handleAddTransactionProof = async () => {
        try {
            const transaction = await getTransactions({ id: transactionIdInputValue }, 0);
            setTransactionIdInputValue('');
            if (transaction.length == 0) {
                toast.error('Erro ao Adicionar Transação!')
            }
            else {
                let newTransactionProof = {
                    abuseReportId: abuseReportIds[0],
                    transactionId: transaction[0].id,
                    type: "transaction",
                    addedBy: '',
                    timestamp: new Date(),
                    data: {
                        item: transaction[0].item == '-' ? null : transaction[0].item,
                        senderId: transaction[0].senderId,
                        senderName: transaction[0].sender,
                        receiverId: transaction[0].receiverId,
                        receiverName: transaction[0].receiver,
                        type: transaction[0].type,
                        details: transaction[0].details,
                        gold: transaction[0].gold,
                        tokens: transaction[0].tokens,
                        modelId: transaction[0].modelId == '-' ? null : transaction[0].modelId,
                        timestamp: moment(transaction[0].timestamp, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss:ss.SSSZ")
                    },
                };

                let verifyExistence = false
                let tempList = [...proofList];
                tempList.map((item) => {
                    if (item.type == 'transaction') {
                        if (item.transactionId == newTransactionProof.transactionId) {
                            verifyExistence = true
                        }
                    }
                })
                if (!verifyExistence) {
                    let tempArr = [...transactionsProof];
                    tempArr.push(transaction[0]);
                    setTransactionsProof(tempArr)

                    tempList.push(newTransactionProof);
                }
                else {
                    toast.error('Transação já adicionada!')
                }
                setProofList(tempList);
            }
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Adicionar Transação!')
        }
    }

    const handleSaveProofList = async () => {
        try {
            let saved = false
            if (originalReportedModNotes !== reportedModNotes) {
                let newModNoteProof = {
                    type: "modNote",
                    timestamp: new Date(),
                    abuseReportId: abuseReportIds[0],
                    data: {
                        note: reportedModNotes,
                        userId: reportedUserId
                    },
                };

                let tempList = [...proofList];
                tempList.push(newModNoteProof);
                setProofList(tempList);
                await saveProofList(tempList);
                saved = true

                let changes = {}
                changes['modNotes'] = reportedModNotes
                await saveUser(reportedUserId, changes)
            }


            if (proofList.length > 0 && !saved) {
                await saveProofList(proofList);
            }

            /*
            if (originalReportedModNotes !== reportedModNotes) {
                let changes = {}
                changes['modNotes'] = reportedModNotes
                await saveUser(reportedUserId, changes)
            }
            */
            handleCloseProofModal();

            fetchAllInfractions(dispatch, filter.form, 0);

            toast.success('Prova(s) Anexada(s) com Sucesso!');
        }
        catch {
            toast.error('Erro ao Anexar Prova(s)!');
        }
    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'ChatLog',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.filterContainer}>
                            <input className={style.modalInput} type="text" placeholder={'ID da Mensagem'} value={chatLogIdInputValue} onChange={handleOnChangeChatLogIdInput} />
                            <Button disabled={chatLogIdInputValue == ''} text="Adicionar" onClick={handleAddChatLogProof} color="cyan" />
                        </div>
                        <div className={proofStyle.infoContainer}>
                            {proofList.map((item, i) => {
                                if (item.type == 'chatLog') {
                                    return (
                                        <ChatMessage key={i} avatarIcon={item.headUrl} avatarFirstName={item.avatarFullName} message={item.data.message} />
                                    )
                                }
                            })}
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Transação',
            content: (
                <>
                    <div className={proofStyle.container}>
                        <div className={proofStyle.filterContainer}>
                            <input className={style.modalInput} type="text" placeholder={'ID de Transação'} value={transactionIdInputValue} onChange={handleOnChangeTransactionIdInput} />
                            <Button disabled={transactionIdInputValue == ''} text="Adicionar" onClick={handleAddTransactionProof} color="cyan" />
                        </div>
                        <div className={proofStyle.transactionInfoContainer}>
                            <BasicTable
                                height='33vh'
                                headers={transactionsHeaders}
                                title='Transações'
                                items={transactionsProof}
                                slotActions={<></>}
                            />
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 3,
            tabTitle: 'Notas',
            content: (
                <div className={proofStyle.container}>
                    <div className="mt-2 p-2">

                        {<textarea rows="4" value={reportedModNotes} onChange={verifyUserNotesEdit ? handleOnChangeReportedModNotes : () => { }} name="comment" id="comment" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[33vh]"></textarea>}
                    </div>
                </div>
            )
        }
    ]


    const handleSelectItem = (item) => {
        if (item.abuseReportId) {
            let tempArr = [...abuseReportIds];
            let tempArrContent = [...abuseReportsContent]
            if (!tempArr.includes(item.abuseReportId)) {
                tempArr.push(item.abuseReportId);
                tempArrContent.push(item)
            }
            else {
                let index = tempArr.indexOf(item.abuseReportId);
                tempArr.splice(index, 1);
                tempArrContent.splice(index, 1);

            }
            setAbuseReportIds(tempArr);
            setAbuseReportsContent(tempArrContent)
        }
    }

    const handleOpenProofModal = () => {
        setIsProofModalOpen(true);
        setProofList([]);

        setOriginalReportedModNotes(abuseReportsContent[0].reportedModNotes)
        setReportedModNotes(abuseReportsContent[0].reportedModNotes)
        setReportedUserId(abuseReportsContent[0].reportedUserId)
        setIsReportedModNotesChange(true)
    }

    return (
        <>
            {verifyInfractions && <div>
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12'>
                        <div className='ring-2 ring-black ring-opacity-10 rounded-lg p-2'>
                            <FormSearch onFilterChange={handleSetFilter} defaultValues={filters} />
                        </div>
                    </div>
                </div>
                <div className='col-span-12'>
                    <BasicTable
                        onAppearHeader={handleAppearHeader}
                        headerControl={true}
                        originalHeaders={infractionsHeaders}
                        headers={infractionsMomentHeaders}
                        onSelectRow={handleSelect}
                        hasButton={verifyReverseInfraction}
                        onClickBtnItem={handleReverseInfraction}
                        onClickBtnItemHeader={'Desativar'}
                        onClickBtnItemInfo={'Desativar'}
                        title='Buscar Infrações'
                        isLoading={isLoading && allInfractions.length === 0}
                        slotActions={<Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />}
                        selectItems={allInfractions.map((value) => {
                            return {
                                item: value,
                                selected: abuseReportIds.includes(value.abuseReportId)
                            }
                        })}
                        isSelectable={true}
                        onSelectItem={handleSelectItem}
                        selectKey={"abuseReportId"}
                        slotFooter={
                            <div className={styles.slotAbuseReportFooter}>
                                <div className={styles.slotFooter}>
                                    <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                                    {(allInfractions.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : allInfractions.length} resultados</span>}
                                </div>
                                
                                <div className={styles.slotAbuseReportBtnsFooter}>
                                    <Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1)} text="Adicionar Prova" onClick={handleOpenProofModal} color="cyan" />
                                    {/*<Button disabled={(abuseReportIds.length == 0 || abuseReportIds.length > 1) || !verifyReverseInfraction} text="Desativar" onClick={handleReverseInfraction} color="cyan" />*/}
                                </div>
                            </div>
                        }
                    />
                </div>
                {
                    <Modal
                        isOpen={isProofListModalOpen}
                        onClose={handleCloseProofListModal}
                    >
                        <div className={proofStyle.proofModalContainer}>
                            {isProofLoading && <div className={proofStyle.proofModalContainerSpin}>
                                <InfinitySpin
                                    width='200'
                                    color="#155f75"
                                />
                            </div>}
                            {!isProofLoading && <Tabs
                                tabs={proofListTabs}
                            />}
                        </div>

                    </Modal>
                }
                {
                    <Modal
                        isOpen={isProofModalOpen}
                        onClose={handleCloseProofModal}
                        footer={
                            <div className={proofStyle.proofModalControler}>
                                <Button disabled={!(proofList.length > 0) && isReportedModNotesChange} text="Descartar" onClick={() => { setProofList([]), setTransactionsProof([]), handleDiscardReportedModNotesChanges(), setReportedModNotes(originalReportedModNotes), setIsReportedModNotesChange(true) }} color="cyan" />
                                <Button disabled={!(proofList.length > 0) && isReportedModNotesChange} text="Salvar" onClick={handleSaveProofList} color="cyan" />
                            </div>
                        }
                    >
                        <div className={proofStyle.proofModalContainer}>
                            <Tabs
                                tabs={tabs}
                            />
                        </div>

                    </Modal>
                }
            </div>}

        </>
    )
}
