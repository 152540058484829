import Verified from '../../../img/verified.png'
import Cross from '../../../img/cross.png'
import Attention from '../../../img/attention.png'
import Notification from '../../../img/notification.png'
import miniIcon from '../../../img/miniIcon.webp'
import moment from 'moment'

class AbuseReport {
    constructor({ date, reason, category, status, reporterFirstName, reporterLastName, reporterNameInstance, reportedFirstName, reportedLastName, reportedNameInstance, assignedUserFirstName, assignedUserLastName, type, reporterId, reportedId, assignedToId, reportedIcon, reporterIcon, abuseReportId, proofList, reportedModNotes, reportedUserId }) {
        this.date = date
        this.reason = reason
        this.category = category
        this.status = status
        this.reporterFirstName = reporterFirstName
        this.reporterLastName = reporterLastName
        this.reporterNameInstance = reporterNameInstance
        this.reportedFirstName = reportedFirstName
        this.reportedLastName = reportedLastName
        this.reportedNameInstance = reportedNameInstance
        this.assignedUserFirstName = assignedUserFirstName
        this.assignedUserLastName = assignedUserLastName
        this.type = type
        this.reporterId = reporterId
        this.reportedId = reportedId
        this.assignedToId = assignedToId
        this.reportedIcon = reportedIcon
        this.reporterIcon = reporterIcon
        this.abuseReportId = abuseReportId
        this.proofList = proofList, 
        this.reportedModNotes = reportedModNotes
        this.reportedUserId = reportedUserId
    }

    get() {
        return {
            reporterFullName: this.avatarFullName(this.reporterFirstName, this.reporterLastName, this.reporterNameInstance, this.type),
            reportedFullName: this.avatarFullName(this.reportedFirstName, this.reportedLastName, this.reportedNameInstance, this.type),
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            responsable: this.responsableStatus,
            statusIcon: this.statusIcon,
            reason: this.reason,
            category: this.category,
            reporterId: this.reporterId,
            reportedId: this.reportedId,
            reporterIcon: this.icon(this.type, this.reporterIcon, this.reporterFirstName, this.reporterLastName),
            reportedIcon: this.icon(this.type, this.reportedIcon, this.reportedFirstName, this.reportedLastName),
            assignedToId: this.assignedToId,
            status: this.status,
            abuseReportId: this.abuseReportId,
            proofList: this.proofList,
            isProofList: this.isProofList,
            reportedModNotes: this.reportedModNotes,
            reportedUserId: this.reportedUserId
        }
    }

    icon(type, iconStr, avatarFirstName, avatarLastName) {
        if (type === 'S') return miniIcon
        else if (type === 'A') {
            return (!avatarFirstName || !avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${iconStr}`
        }
        else if (type === 'U') return null
    }

    get isProofList() {
        if (this.proofList) {
            if (this.proofList.length == 0 || this.proofList == null) {
                return 'Não'
            }
            else {
                return 'Sim'
            }
        }
        else {
            return 'Não'
        }
    }

    get statusIcon() {
        const info = {
            'U': Notification,
            'A': Attention,
            'R': Verified,
            'B': Cross,
        }

        return info[this.status]
    }

    get responsableStatus() {
        const info = {
            'U': '',
            'A': `Atribuído à ${this.assignedUserFirstName} ${this.assignedUserLastName}`,
            'R': `Resolvido por ${this.assignedUserFirstName} ${this.assignedUserLastName}`,
            'B': `Fechado por ${this.assignedUserFirstName} ${this.assignedUserLastName}`,
        }

        return info[this.status]
    }

    avatarFullName(avatarFirstName, avatarLastName, avatarNameInstance, type) {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (avatarNameInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarNameInstance);
        else if (avatarFirstName === null || avatarLastName === null && type == 'S') return "Sistema"
        else return avatarFirstName + " " + avatarLastName;
    }
}

export default AbuseReport