import moment from "moment"

class PackageDetail {
    constructor({ packageId, name, fullWidth, sortIndex, metadata, disabled, currency, images, prices, discountEndDate, discountStartDate, priceDiscount, hasVipDiscount, lifetimeLimit, value, startDate, endDate, description}) {
        this.packageId = packageId
        this.name = name
        this.fullWidth = fullWidth
        this.sortIndex = sortIndex
        this.metadata = metadata
        this.disabled = disabled
        this.currency = currency
        this.images = images
        this.prices = prices
        this.discountEndDate = discountEndDate
        this.discountStartDate = discountStartDate
        this.priceDiscount = priceDiscount
        this.hasVipDiscount = hasVipDiscount
        this.lifetimeLimit = lifetimeLimit
        this.value = value
        this.startDate = startDate
        this.endDate = endDate
        this.description = description
    }

    get() {
        return {
            tableProperties: [
                {
                    key: 'packageId',
                    name: "ID Pacote",
                    value: this.packageId,
                },
                {
                    key: 'name',
                    name: "Nome",
                    value: this.name,
                },
                {
                    key: 'description',
                    name: "Descrição",
                    value: this.description,
                },
                {
                    key: 'startDate',
                    name: "Data de Entrada",
                    value: this.startDate == '-' ? '-' : moment(this.startDate).format('DD/MM/YYYY') + " " + moment(this.startDate).format('HH:mm:ss'),
                },
                {
                    key: 'endDate',
                    name: "Data de Saída",
                    value: this.endDate == '-' ? '-' : moment(this.endDate).format('DD/MM/YYYY') + " " + moment(this.endDate).format('HH:mm:ss'),
                },
                {
                    key: 'fullWidth',
                    name: "Ocupa linha inteira",
                    value: this.fullWidth,
                },
                {
                    key: 'sortIndex',
                    name: "Prioridade para Exibição",
                    value: this.sortIndex,
                },
                {
                    key: 'disabled',
                    name: "Desabilitado",
                    value: this.disabled,
                },
                {
                    key: 'currency',
                    name: "Moeda",
                    value: this.currency,
                },
                {
                    key: 'lifetimeLimit',
                    name: "Limit",
                    value: this.lifetimeLimit,
                },
                {
                    key: 'discountStartDate',
                    name: "Início Desconto",
                    value: this.discountStartDate == '-' ? '-' : moment(this.discountStartDate).format('DD/MM/YYYY') + " " + moment(this.discountStartDate).format('HH:mm:ss'),
                },
                {
                    key: 'discountEndDate',
                    name: "Término Desconto",
                    value: this.discountEndDate == '-' ? '-' : moment(this.discountEndDate).format('DD/MM/YYYY') + " " + moment(this.discountEndDate).format('HH:mm:ss'),
                },
                {
                    key: 'priceDiscount',
                    name: "Desconto",
                    value: this.priceDiscount,
                },
                {
                    key: 'hasVipDiscount',
                    name: "Desconto VIP",
                    value: this.hasVipDiscount,
                },
                {
                    key: 'metadataGold',
                    name: "Moedas",
                    value: this.metadata?.gold ?? 0,
                },
                {
                    key: 'metadataCl',
                    name: "Nível Cidadão",
                    value: this.metadata?.cl ?? 0,
                },
                {
                    key: 'metadataVip',
                    name: "Meses de VIP",
                    value: this.metadata?.vip ?? 0,
                },
                {
                    key: 'hasMetadataItems',
                    name: "Items",
                    value: this.metadata?.items && this.metadata?.items.length >0 ? 'Sim' : 'Não',
                },
                {
                    key: 'brlPrice',
                    name: "Preço em BRL",
                    value: this.prices?.BRL,
                },
                {
                    key: 'usdPrice',
                    name: "Preço em USD",
                    value: this.prices?.USD,
                },
                {
                    key: 'eurPrice',
                    name: "Preço em EUR",
                    value: this.prices?.EUR,
                },
                {
                    key: 'gbpPrice',
                    name: "Preço em GBP",
                    value: this.prices?.GBP,
                },

                {
                    key: 'brlImage',
                    name: "Imagem BRL",
                    value: this.images?.BRL,
                },
                {
                    key: 'usdImage',
                    name: "Imagem USD",
                    value: this.images?.USD,
                },
                {
                    key: 'eurImage',
                    name: "Imagem EUR",
                    value: this.images?.EUR,
                },
                {
                    key: 'gbpImage',
                    name: "Imagem GBP",
                    value: this.images?.GBP,
                },    
            ],
            packageId: this.packageId,
            name: this.name,
            fullWidth: this.fullWidth,
            sortIndex: this.sortIndex,
            disabled: this.disabled,
            currency: this.currency,
            discountEndDate: this.discountEndDate,
            discountStartDate: this.discountStartDate,
            priceDiscount: this.priceDiscount,
            hasVipDiscount: this.hasVipDiscount,
            metadata: this.metadata,
            metadataGold: this.metadata?.gold ?? 0,
            metadataCl: this.metadata?.cl ?? 0,
            metadataVip: this.metadata?.vip ?? 0,
            hasMetadataItems: this.metadata?.items && this.metadata?.items.length >0 ? 'Sim' : 'Não',
            metadataItems: this.metadata?.items ?? [],
            lifetimeLimit: this.lifetimeLimit,
            value: this.value,
            prices: this.prices,
            brlPrice: this.prices?.BRL,
            usdPrice: this.prices?.USD,
            eurPrice: this.prices?.EUR,
            gbpPrice: this.prices?.GBP,
            images: this.images,
            brlImage: this.images?.BRL,
            usdImage: this.images?.USD,
            eurImage: this.images?.EUR,
            gbpImage: this.images?.GBP,
            startDate: this.startDate,
            endDate: this.endDate,
            description: this.description

        }

    }

}

export default PackageDetail