import moment from "moment"

class Ip {
    constructor ({userId, date, ip, firstName, lastName}){
        this.userId = userId
        this.date = date
        this.ip = ip
        this.firstName =  firstName
        this.lastName = lastName
    }

    get(){
        return{
            userId: this.userId,
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            ip: this.ip,
            firstName: this.firstName,
            lastName: this.lastName,
            userName: this.userName
        }
    }

    get userName(){
        return `${this.firstName} ${this.lastName}`
    }
}

export default Ip