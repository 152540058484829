import moment from "moment"

class WebSubscription {
    constructor({ discord, userId, status, lastUpdate, userFirstName, userLastName }) {
        this.discord = discord
        this.userId = userId
        this.status = status
        this.lastUpdate = lastUpdate

        
        this.userFirstName = userFirstName
        this.userLastName = userLastName
    }

    get() {
        return {
            discord: this.discord,
            userId: this.userId,
            smallUserId: this.smallId(this.userId),

            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userName: this.fullName(this.userFirstName, this.userLastName),

            lastUpdate: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            status: this.statusProp(this.status) ?? 'Status Desconhecido',
        }
    }

    statusProp(status) {
        const statusName = {
            pending: 'Inscrição realizada',
            review: 'Em análise',
            approved: 'Aceito',
            denied: 'Negado',
        }
        return statusName[status]
    }

    fullName(userFirstName, userLastName) {
        if (userFirstName === null || userLastName === null) return "Usuário Apagado"
        else return `${userFirstName} ${userLastName}`
    }

    smallId(id) {
        return id?.slice(0, 7)
    }
}

export default WebSubscription