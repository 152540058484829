import React, { useEffect, useState } from 'react'
import Button from 'components/form/Button'
import Modal from 'components/Modal'
import style from '../../../../styles/Proof.module.css'
import styles from '../../../../styles/UserTabs.module.css'
import { uuidMask } from 'helpers/masks'
import { getInfractionOptions, getUserInfo, getUserInfractionPoints, getUserInfractionPointsByUserId, getUserStep, getUserStepByUserId, saveUserInfractionByUserId } from 'services/infractionService'
import { toast } from 'react-toastify'
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode'
import useUserPermission from 'domains/Permissions/useUserPermission'
import { BiCoin } from 'react-icons/bi'
import { PiPokerChipBold } from 'react-icons/pi'
import { ChatMessage } from 'Pages/Spaces/Partials/chatMessage'
import BasicTable from 'components/BasicTable'
import { getChatLog } from 'services/chatLogService'
import { Paths } from 'paths/Paths'
import { getTransactions } from 'services/transactionService'
import Tabs from 'components/Tabs'
import moment from 'moment'
import { saveUser } from 'services/userService'

export default function RegisterInfraction() {
    const verifyInfract = useUserPermission(SMIPermissionsCode.MOD_GLOBALLY_INFRACT_USERS);
    const verifyUserNotes = useUserPermission(SMIPermissionsCode.MOD_USER_NOTES_VIEW);
    const verifyUserNotesEdit = useUserPermission(SMIPermissionsCode.MOD_CAN_REMOVE_AUTHENTICATOR);

    const [abuseReportsContent, setAbuseReportsContent] = useState({});
    const [originalReportedModNotes, setOriginalReportedModNotes] = useState('')
    const [reportedModNotes, setReportedModNotes] = useState('')
    const [reportedUserId, setReportedUserId] = useState('')
    const [defProofList, setDefProofList] = useState([])

    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setDefProofList([])
        setUserIdInputValue("")
        setUserPoints(0)
        setNewPoints(0)
        setPunishment("")
        setFoundUser(false)
        setIsModalOpen(false)
    }

    const [infractionOptions, setInfractionOptions] = useState([])
    const [inputValue, setInputValue] = useState("")
    const [userIdInputValue, setUserIdInputValue] = useState("")
    const [userPoints, setUserPoints] = useState(0)
    const [newPoints, setNewPoints] = useState(0)
    const [punishment, setPunishment] = useState("")
    const [foundUser, setFoundUser] = useState(false)

    useEffect(() => {
        handleInfractionOptions()
    }, [])

    const handleInfractionOptions = async () => {
        let apiInfractionOptions = await getInfractionOptions()
        setInputValue(JSON.stringify({ typeId: apiInfractionOptions[0].typeId, points: apiInfractionOptions[0].points }))
        setInfractionOptions(apiInfractionOptions)
    }

    const handleOnChangeInput = async (event) => {
        const selectedValue = JSON.parse(event.target.value);
        const typeId = selectedValue.typeId;
        const points = selectedValue.points;

        handleSetStep(typeId)

        setInputValue(event.target.value)

        setNewPoints(userPoints + points)
    }

    const handleSetStep = async (typeId) => {
        if (foundUser) {
            const tempStep = await getUserStepByUserId(typeId, userIdInputValue)
            setPunishment(tempStep.step.name)
        }
    }

    const handleOnChangeUserIdInput = async (event) => {
        setUserIdInputValue(event.target.value)
    }

    const handleSearchUser = async () => {
        try {
            let userInfractionPoints = await getUserInfractionPointsByUserId(userIdInputValue)

            const selectedValue = JSON.parse(inputValue);
            const typeId = selectedValue.typeId;
            const points = selectedValue.points;
            const tempStep = await getUserStepByUserId(typeId, userIdInputValue)
            //pegarmodNotes
            const userInfo = await getUserInfo(userIdInputValue)
            setAbuseReportsContent(userInfo)


            setPunishment(tempStep.step.name)
            setUserPoints(userInfractionPoints.currentPoints)
            setNewPoints(userInfractionPoints.currentPoints + points)

            setFoundUser(true)
            toast.success("Usuário Encontrado com Sucesso!")
        }
        catch (err) {
            console.log(err)
            setFoundUser(false)
            toast.error("Erro ao Encontrar Usuário!")
        }
    }

    const handleSaveUserInfraction = async () => {
        try {
            const selectedValue = JSON.parse(inputValue);
            await saveUserInfractionByUserId(selectedValue.typeId, userIdInputValue, defProofList);

            handleCloseModal()
            toast.success('Infração Aplicada com Sucesso!')
        }
        catch {
            toast.error('Erro ao Aplicar Infração!')
        }
    }

    //Provas

    const [isReportedModNotesChange, setIsReportedModNotesChange] = useState(true)
    const [isProofModalOpen, setIsProofModalOpen] = useState(false);
    const [chatLogIdInputValue, setChatLogIdInputValue] = useState('');
    const [transactionIdInputValue, setTransactionIdInputValue] = useState('');
    const [proofList, setProofList] = useState([]);
    const [transactionsProof, setTransactionsProof] = useState([]);

    const handleOnChangeChatLogIdInput = (e) => {
        setChatLogIdInputValue(e.target.value);
    }

    const handleOnChangeTransactionIdInput = (e) => {
        setTransactionIdInputValue(e.target.value);
    }

    const handleOnChangeReportedModNotes = (e) => {
        setReportedModNotes(e.target.value);
    }

    useEffect(() => {
        if (originalReportedModNotes != reportedModNotes) {
            setIsReportedModNotesChange(false)
        }
        else {
            setIsReportedModNotesChange(true)
        }
    }, [reportedModNotes])

    const handleDiscardReportedModNotesChanges = () => {
        setReportedModNotes(originalReportedModNotes)
        setIsReportedModNotesChange(true)

    }

    const handleOpenProofModal = () => {
        setIsProofModalOpen(true);
        setProofList([]);

        setOriginalReportedModNotes(abuseReportsContent.modNotes)
        setReportedModNotes(abuseReportsContent.modNotes)
        setReportedUserId(userIdInputValue)

        setIsReportedModNotesChange(true)
    }

    const handleCloseProofModal = () => {
        setIsProofModalOpen(false);
        setTransactionIdInputValue('');
        setChatLogIdInputValue('');
        setProofList([]);
        setTransactionsProof([]);

        setOriginalReportedModNotes('')
        setReportedModNotes('')
        setReportedUserId('')
        setIsReportedModNotesChange(true)
    }

    const handleAddChatLogProof = async () => {
        try {
            const msg = await getChatLog({ id: chatLogIdInputValue });
            setChatLogIdInputValue('');
            if (!msg.hasOwnProperty("messageId")) {
                toast.error('Erro ao Adicionar ChatLog!')
            }
            else {
                let newMsgProof = {
                    msgId: msg.messageId,
                    headUrl: Paths.getAvatarHeadURL(msg.avatarId, msg.headPostfix),
                    avatarFullName: msg.avatarName,
                    type: "chatLog",
                    timestamp: new Date(),
                    data: {
                        id: msg.avatarId,
                        message: msg.message,
                        timestamp: moment(msg.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss:ss.SSSZ")
                    },
                };

                let verifyExistence = false
                let tempList = [...proofList];
                tempList.map((item) => {
                    if (item.type == 'chatLog') {
                        if (item.msgId == newMsgProof.msgId) {
                            verifyExistence = true
                        }
                    }
                })
                if (!verifyExistence) {
                    tempList.push(newMsgProof);
                }
                else {
                    toast.error('ChatLog já adicionado!')
                }
                setProofList(tempList);
            }
        }
        catch {
            toast.error('Erro ao Adicionar ChatLog!')
        }
    }

    const transactionsHeaders = [
        { title: 'Tipo', key: 'type', copy: 'type' },
        { title: 'Remetente', key: 'sender', copy: 'senderId' },
        { title: 'Destinatário', key: 'receiver', copy: 'receiverId' },
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' /> },
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' /> },
        { title: 'Detalhes', key: 'details' },
        { title: 'Data', key: 'timestamp' },
    ]

    const handleAddTransactionProof = async () => {
        try {
            const transaction = await getTransactions({ id: transactionIdInputValue }, 0);
            setTransactionIdInputValue('');
            if (transaction.length == 0) {
                toast.error('Erro ao Adicionar Transação!')
            }
            else {
                let newTransactionProof = {
                    transactionId: transaction[0].id,
                    type: "transaction",
                    addedBy: '',
                    timestamp: new Date(),
                    data: {
                        item: transaction[0].item == '-' ? null : transaction[0].item,
                        senderId: transaction[0].senderId,
                        senderName: transaction[0].sender,
                        receiverId: transaction[0].receiverId,
                        receiverName: transaction[0].receiver,
                        type: transaction[0].type,
                        details: transaction[0].details,
                        gold: transaction[0].gold,
                        tokens: transaction[0].tokens,
                        modelId: transaction[0].modelId == '-' ? null : transaction[0].modelId,
                        timestamp: moment(transaction[0].timestamp, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss:ss.SSSZ")
                    },
                };

                let verifyExistence = false
                let tempList = [...proofList];
                tempList.map((item) => {
                    if (item.type == 'transaction') {
                        if (item.transactionId == newTransactionProof.transactionId) {
                            verifyExistence = true
                        }
                    }
                })
                if (!verifyExistence) {
                    let tempArr = [...transactionsProof];
                    tempArr.push(transaction[0]);
                    setTransactionsProof(tempArr)

                    tempList.push(newTransactionProof);
                }
                else {
                    toast.error('Transação já adicionada!')
                }
                setProofList(tempList);
            }
        }
        catch (err) {
            console.log(err)
            toast.error('Erro ao Adicionar Transação!')
        }
    }

    const handleSaveProofList = async () => {
        try {
            let saved = false
            if (originalReportedModNotes !== reportedModNotes) {
                let newModNoteProof = {
                    type: "modNote",
                    timestamp: new Date(),
                    data: {
                        note: reportedModNotes,
                        userId: reportedUserId
                    },
                };

                let tempList = [...proofList];
                tempList.push(newModNoteProof);
                setDefProofList(tempList);
                saved = true

                let changes = {}
                changes['modNotes'] = reportedModNotes
                await saveUser(reportedUserId, changes)
            }


            if (proofList.length > 0 && !saved) {
                let tempList = [...proofList];
                setDefProofList(tempList);
            }


            handleCloseProofModal();

            toast.success('Prova(s) Anexada(s) com Sucesso!');
        }
        catch {
            toast.error('Erro ao Anexar Prova(s)!');
        }
    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'ChatLog',
            content: (
                <>
                    <div className={style.container}>
                        <div className={style.filterContainer}>
                            <input className={styles.modalInput} type="text" placeholder={'ID da Mensagem'} value={chatLogIdInputValue} onChange={handleOnChangeChatLogIdInput} />
                            <Button disabled={chatLogIdInputValue == ''} text="Adicionar" onClick={handleAddChatLogProof} color="cyan" />
                        </div>
                        <div className={style.infoContainer}>
                            {proofList.map((item, i) => {
                                if (item.type == 'chatLog') {
                                    return (
                                        <ChatMessage key={i} avatarIcon={item.headUrl} avatarFirstName={item.avatarFullName} message={item.data.message} />
                                    )
                                }
                            })}
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 2,
            tabTitle: 'Transação',
            content: (
                <>
                    <div className={style.container}>
                        <div className={style.filterContainer}>
                            <input className={styles.modalInput} type="text" placeholder={'ID de Transação'} value={transactionIdInputValue} onChange={handleOnChangeTransactionIdInput} />
                            <Button disabled={transactionIdInputValue == ''} text="Adicionar" onClick={handleAddTransactionProof} color="cyan" />
                        </div>
                        <div className={style.transactionInfoContainer}>
                            <BasicTable
                                height='33vh'
                                headers={transactionsHeaders}
                                title='Transações'
                                items={transactionsProof}
                                slotActions={<></>}
                            />
                        </div>

                    </div>
                </>
            )
        },
        {
            id: 3,
            tabTitle: 'Notas',
            content: (
                <div className={style.container}>
                    <div className="mt-2 p-2">
                        {<textarea rows="4" value={reportedModNotes} onChange={verifyUserNotesEdit ? handleOnChangeReportedModNotes : () => { }} name="comment" id="comment" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-[33vh]"></textarea>}
                    </div>
                </div>
            )
        }
    ]

    return (
        <>
            {verifyInfract && <div>
                <Modal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    header={<h3>Registrar Infração</h3>}
                    footer={
                        <div className={style.userInfractionModalControler}>
                            <Button disabled={!foundUser} text="Adicionar Provas" onClick={handleOpenProofModal} color="cyan" />
                            <Button disabled={!foundUser} text="Salvar" onClick={handleSaveUserInfraction} color="cyan" />
                        </div>}
                >
                    <div className={style.infractionModalContainer}>
                        <div className={style.infractionModalContainerUser}>
                            <input value={userIdInputValue} className={styles.modalInput} onChange={handleOnChangeUserIdInput} placeholder='ID do Usuário' />
                            <Button text="Buscar" onClick={handleSearchUser} color="cyan" />
                        </div>
                        <select value={inputValue} className={styles.select} onChange={handleOnChangeInput}>
                            {infractionOptions && infractionOptions.length > 0 ? (
                                infractionOptions.map((item, index) => (
                                    <option key={item.typeId} value={JSON.stringify({ typeId: item.typeId, points: item.points })}>
                                        {`${item.desc} (${item.points} pontos)`}
                                    </option>
                                ))
                            ) : (
                                <option disabled>Sem opções</option>
                            )}
                        </select>
                        {foundUser &&
                            <div className={style.pointsInfo}>
                                <span>Pontos Atuais: {userPoints}</span>
                                <span>Nova Pontuação: {newPoints}</span>
                                <span>Punição: {punishment}</span>
                            </div>
                        }
                    </div>
                </Modal>
                <Modal
                    isOpen={isProofModalOpen}
                    onClose={handleCloseProofModal}
                    footer={
                        <div className={style.proofModalControler}>
                            <Button disabled={!(proofList.length > 0) && isReportedModNotesChange} text="Descartar" onClick={() => { setProofList([]), setTransactionsProof([]), handleDiscardReportedModNotesChanges() }} color="cyan" />
                            <Button disabled={!(proofList.length > 0) && isReportedModNotesChange} text="Salvar" onClick={handleSaveProofList} color="cyan" />
                        </div>
                    }
                >
                    <div className={style.proofModalContainer}>
                        <Tabs
                            tabs={tabs}
                        />
                    </div>

                </Modal>
                <button onClick={handleOpenModal} className='block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-cyan-200 w-full text-left'>Registrar Infração</button>
            </div>}
        </>
    )
}
