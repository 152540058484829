import moment from "moment"

class ModLog {
    constructor({ date, property, oldValue, newValue, modFirstName, modLastName, modId }) {
        this.date = date
        this.property = property
        this.oldValue = oldValue
        this.newValue = newValue
        this.modFirstName = modFirstName
        this.modLastName = modLastName
        this.modId = modId
    }

    get() {
        return {
            modId: this.modId,
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            property: this.property,
            oldValue: this.oldValue,
            newValue: this.newValue,
            modName: this.modFirstName + " " + this.modLastName

        }
    }
}

export default ModLog