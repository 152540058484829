import moment from "moment"

class StoreLog {
    constructor({ purchaseId, userId, paymentMethod, status, date, content, userFirstName, userLastName }) {
        this.purchaseId = purchaseId
        this.userId = userId
        this.paymentMethod = paymentMethod
        this.status = status
        this.date = date
        this.content = content
        this.userFirstName = userFirstName
        this.userLastName = userLastName
    }

    get() {
        return ({
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            smallPurchaseId: this.smallId(this.purchaseId),
            purchaseId: this.purchaseId,
            userName: this.fullName(this.userFirstName, this.userLastName),
            userId: this.userId,
            status: this.getStatus(this.status),
            paymentMethod: this.getMethod(this.paymentMethod),
            packages: this.content != null ? this.getPackages(this.content) : '',
        })
    }

    smallId(id) {
        return id?.slice(0, 7)
    }

    fullName(userFirstName, userLastName) {
        if (userFirstName === null || userLastName === null) return "Usuário Apagado"
        else return `${userFirstName} ${userLastName}`
    }

    getStatus(status) {
        const types = {
            'unpaid': 'Não',
            'paid': 'Sim',
        }
        return types[status]
    }

    getMethod(status) {
        const types = {
            'xsolla': 'Xsolla',
            'pagarme': 'Pagarme',
        }
        return types[status]
    }

    getPackages(content){
        let packages = ''
        for(let i = 0; i<content.length; i++){
            let packName = content[i].name
            let packQuantity = content[i].quantity
            
            packages = `${packages} (${packQuantity}) - ${packName} /` 
        }

        return packages.slice(0, -1);
    }
}

export default StoreLog