import moment from "moment";
import styles from '../../styles/Search.module.css'
import styleTabs from '../../styles/UserTabs.module.css'
import style from '../../styles/News.module.css'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/form/Button";
import BasicTable from "components/BasicTable";
import Details from './Partials/Details';
import Tabs from "components/Tabs";
import Modal from "components/Modal";
import Select from 'react-select'
import { createNews, getNewsCategories } from "services/newsService";
import { toast } from "react-toastify";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import useUserPermission from "domains/Permissions/useUserPermission";
import { BiCoin } from "react-icons/bi";
import { PiPokerChipBold } from "react-icons/pi";
import { fetchTournaments } from "store/modules/tournaments/tournamentsSlice";
import TournamentsFilter from "./Partials/tournamentsFilter";
import { selectTournamentForDetails } from "store/modules/tournaments/selectedTournamentSlice";
import styleNews from '../../styles/News.module.css'
import { createTournament } from "services/tournamentsService";

export default function Tournaments({ tournamentId, gameId }) {

    const verifyTournament = useUserPermission(SMIPermissionsCode.MOD_TOURNAMENT_MANAGE);

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [nameInput, setNameInput] = useState('');
    const [detailsInput, setDetailsInput] = useState('');
    const [gameIdInput, setGameIdInput] = useState('');
    const [bountyGoldInput, setBountyGoldInput] = useState('');
    const [bountyTokensInput, setBountyTokensInput] = useState('');
    const [groupInput, setGroupInput] = useState(false);
    const [endTimeInput, setEndTimeInput] = useState('');
    const [firstInput, setFirstInput] = useState('');
    const [secondInput, setSecondInput] = useState('');
    const [thirdInput, setThirdInput] = useState('');
    const [isFull, setIsFull] = useState(false)


    const [tournamentsHeaders, setTournamentsHeaders] = useState([
        { title: 'Encerramento', key: 'endTime', appear: true },
        { title: 'Nome', key: 'name', copy: 'tournamentId', detailable: 'true', appear: true },
        { title: 'Detalhes', key: 'details', appear: true },
        { title: 'ID do Jogo', key: 'gameId', copy: 'gameId', appear: true },
        { title: 'Moedas', key: 'bountyGold', icon: () => <BiCoin className='w-4 h-4 text-white' />, appear: true },
        { title: 'Fichas', key: 'bountyTokens', icon: () => <PiPokerChipBold className='w-4 h-4 text-white' />, appear: true },
        { title: 'Em grupo', key: 'groupEntry', appear: true },
        { title: '1º', key: 'firstPlace', appear: true },
        { title: '2º', key: 'secondPlace', appear: true },
        { title: '3º', key: 'thirdPlace', appear: true },

    ]);

    const [tournamentsMomentHeaders, setTournamentsMomentHeaders] = useState([...tournamentsHeaders]);

    const handleAppearHeader = (item) => {
        setTournamentsHeaders((prevHeaders) => {
            const updatedHeaders = prevHeaders.map((header) =>
                header.key === item.key ? { ...header, appear: !header.appear } : header
            );

            setTournamentsMomentHeaders(updatedHeaders.filter(header => header.appear));

            return updatedHeaders;
        });
    }


    const [filter, setFilter] = useState({})
    const [isLoading, setLoading] = useState(false)

    const tournaments = useSelector((state) => {
        return Array.isArray(state.tournaments.data) ? state.tournaments.data : []
    })

    const selectedTournament = useSelector((state) => {
        return state.selectedTournament
    })

    const skip = useSelector((state) => {
        return (state.tournaments.currentSkip) ? state.tournaments.currentSkip : 0
    })
    const hasMore = useSelector((state) => {
        return Boolean(state.tournaments.hasMore)
    })
    const filters = useSelector((state) => {
        return Object(state.tournaments.filters) ? state.tournaments.filters : {}
    })

    useEffect(() => {
        setLoading(false)
    }, [tournaments]);

    const dispatch = useDispatch()

    useEffect(() => {
        handleParams()
    }, [])

    const handleParams = async () => {
        if (tournamentId || gameId) {
            setLoading(true)
            fetchTournaments(dispatch, { tournamentId, gameId }, 0);
        }
    }

    const search = () => {
        setLoading(true)
        const query = new URLSearchParams();
        if (filter.form.tournamentId) query.append('tournamentId', filter.form.tournamentId);
        if (filter.form.gameId) query.append('gameId', filter.form.gameId);
        window.history.pushState({}, '', `/tournaments?${query.toString()}`);
        fetchTournaments(dispatch, filter.form, 0);

    }

    const handleSetFilter = ({ form, isValid }) => {
        setFilter({ form, isValid })
    }

    const handleLoadMore = (() => {
        setLoading(true);
        fetchTournaments(dispatch, filter.form, skip + 200);
    })

    const handleNewsDetails = (item) => {
        dispatch(selectTournamentForDetails({ tournamentId: item.tournamentId }))
    }

    const handleOpenCreateModal = async () => {
        setIsCreateModalOpen(true)
    }

    const handleCloseCreateModal = () => {
        setNameInput('');
        setDetailsInput('');
        setGameIdInput('');
        setBountyGoldInput('');
        setBountyTokensInput('');
        setGroupInput(false);
        setEndTimeInput('');
        setFirstInput('');
        setSecondInput('');
        setThirdInput('');
        setIsFull(false);
        setIsCreateModalOpen(false)
    }

    useEffect(() => {
        if (nameInput.trim() != '' && detailsInput.trim() != '' && gameIdInput.trim() != '' && bountyGoldInput != '' && bountyTokensInput != '' && endTimeInput != '' && firstInput != '' && secondInput != '' && thirdInput != '') {
            setIsFull(true)
        }
        else {
            setIsFull(false)
        }

    }, [nameInput, detailsInput, gameIdInput, bountyGoldInput, bountyTokensInput, endTimeInput, firstInput, secondInput, thirdInput])

    const handleCreateTournament = async () => {
        try {
            await createTournament({ desc: nameInput, details: detailsInput, gameId: gameIdInput, bountyGold: bountyGoldInput, bountyTokens: bountyTokensInput, endTime: moment(endTimeInput).utc().format(), groupEntry: groupInput, tdist_value: `${firstInput}:${secondInput}:${thirdInput}` })
            handleCloseCreateModal();
            await fetchTournaments(dispatch, {}, 0);
            toast.success("Dados Salvos com Sucesso!")
        }
        catch {
            toast.error("Erro ao Salvar Dados!")
        }
    }

    return (
        <div className='grid grid-cols-12 gap-4'>

            {verifyTournament && <div className='col-span-9'>
                <BasicTable
                    onAppearHeader={handleAppearHeader}
                    headerControl={true}
                    originalHeaders={tournamentsHeaders}
                    headers={tournamentsMomentHeaders}
                    slotHeader={<TournamentsFilter onFilterChange={handleSetFilter} defaultValues={filters} />}
                    title='Buscar Torneios'
                    onDetailRow={handleNewsDetails}
                    isLoading={isLoading && tournaments.length === 0}
                    slotActions={
                        <>
                            <Button disabled={!filter.isValid} text="Buscar" onClick={search} color="cyan" isLoading={isLoading} />
                            {<Button text="Criar Torneio" onClick={handleOpenCreateModal} color="cyan" />}
                        </>
                    }
                    items={tournaments}
                    slotFooter={
                        <div className={styles.slotFooter}>
                            <Button disabled={!hasMore || isLoading} text="Carregar Mais" onClick={handleLoadMore} color="cyan" />
                            {(tournaments.length === 0) ? "" : <span>Exibindo {0}-{(hasMore) ? skip + 200 : tournaments.length} resultados</span>}
                        </div>
                    }
                />
                <Modal
                    header={`Criar Torneio`}
                    isOpen={isCreateModalOpen}
                    onClose={handleCloseCreateModal}
                    footer={<div className={styleNews.modalFooter}><Button disabled={!isFull} text="Salvar" onClick={handleCreateTournament} color="cyan" /></div>}
                >
                    <div className={styleNews.modalCreateNewsContainer} style={{ width: '50vw', height: 'auto' }}>
                        <div className={styleNews.propContainerContent}>
                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Nome`}</span>
                                <input className={styleNews.modalInput} type="text" placeholder={`Nome`} value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
                            </div>

                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Detalhes`}</span>
                                <input className={styleNews.modalInput} type="text" placeholder={`Detalhes`} value={detailsInput} onChange={(e) => setDetailsInput(e.target.value)} />
                            </div>

                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`ID do jogo`}</span>
                                <input className={styleNews.modalInput} type="text" placeholder={`ID do jogo`} value={gameIdInput} onChange={(e) => setGameIdInput(e.target.value)} />
                            </div>
                        </div>

                        <div className={styleNews.propContainerContent}>
                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Premiação em Moedas`}</span>
                                <input className={styleNews.modalInput} type="number" placeholder={`Premiação em Moedas`} value={bountyGoldInput} min="0" onChange={(e) => setBountyGoldInput(e.target.value)} />
                            </div>

                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Premiação em Fichas`}</span>
                                <input className={styleNews.modalInput} type="number" placeholder={`Premiação em Fichas`} value={bountyTokensInput} min="0" onChange={(e) => setBountyTokensInput(e.target.value)} />
                            </div>
                        </div>

                        <div className={styleNews.propContainerContent}>
                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Data de Término`}</span>
                                <input className={styleNews.modalInput} type="datetime-local" placeholder={`Data de Término`} value={endTimeInput} min="0" onChange={(e) => setEndTimeInput(e.target.value)} />
                            </div>

                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`Grupo`}</span>
                                <select value={groupInput} className={styleTabs.select} onChange={(e) => setGroupInput(e.target.value)}>
                                    <option key={'Sim'} value={true}>Sim</option>
                                    <option key={'Não'} value={false}>Não</option>
                                </select>
                            </div>
                        </div>

                        <div className={styleNews.propContainerContent}>
                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`1ª Premiação (partes)`}</span>
                                <input className={styleNews.modalInput} type="number" placeholder={`1ª Premiação (partes)`} value={firstInput} min="0" onChange={(e) => setFirstInput(e.target.value)} />
                            </div>

                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`2ª Premiação (partes)`}</span>
                                <input className={styleNews.modalInput} type="number" placeholder={`2ª Premiação (partes)`} value={secondInput} min="0" onChange={(e) => setSecondInput(e.target.value)} />
                            </div>

                            <div className={styleNews.generalContainerInput}>
                                <span className={styleTabs.modalLabel}>{`3ª Premiação (partes)`}</span>
                                <input className={styleNews.modalInput} type="number" placeholder={`3ª Premiação (partes)`} value={thirdInput} min="0" onChange={(e) => setThirdInput(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>}
            <div className='col-span-3'>

                {selectedTournament.tournamentId && <Details item={selectedTournament} />}

            </div>
        </div>
    )
}