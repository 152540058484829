import useUserPermission from "domains/Permissions/useUserPermission";
import { UsePackage } from "./packageSearch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicTable from "components/BasicTable";
import Button from "components/form/Button";
import Modal from "components/Modal";
import { SMIPermissionsCode } from "domains/Permissions/SMIPermissionsCode";
import style from '../../../styles/UserTabs.module.css'
import Tabs from "components/Tabs";
import moment from "moment";
import { fetchModelList } from "store/modules/items/modelListSlice";
import Select from 'react-select'
import { toast } from "react-toastify";
import { fetchSelectedPackageDetails } from "store/modules/packages/selectedPackageDetailSlice";
import { savePackage, savePackageImg, savePackageItems } from "services/packagesService";
import { BiCoin } from "react-icons/bi";
import { PiPokerChipBold } from "react-icons/pi";

export default function Details({ item }) {
    const verifyPackagesManage = useUserPermission(SMIPermissionsCode.PACKAGES_MANAGE);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '20vh',
            overflowY: 'auto'
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#155e75' : '#d1d5db',
            '&:hover': {
                borderColor: '#d1d5db'
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6b7280'
        })
    };

    const packagesHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const iconObject = { key: 'modelIcon' }

    const modelItemsHeaders = [
        { title: 'ID do Modelo', key: 'modelId', copy: 'modelId' },
        { title: 'Nome', key: 'modelName', icon: () => iconObject },
        { title: 'Moedas', key: 'gold', icon: <BiCoin className='w-4 h-4 mr-1 text-yellow-500' />},
        { title: 'Fichas', key: 'tokens', icon: <PiPokerChipBold className='w-4 h-4 mr-1 text-rose-500' />},
        { title: 'Configuração', key: 'config' }
    ]

    const [modelItems, setModelItems] = useState([])
    const [selectInput, setSelectInput] = useState('')
    const [goldInput, setGoldInput] = useState('')
    const [tokensInput, setTokensInput] = useState('')
    const [configInput, setConfigInput] = useState('')
    const [modelIsValid, setModelIsValid] = useState(false)

    const firstPackageData = UsePackage(item);
    const [packageData, setPackageData] = useState(firstPackageData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    const modelList = useSelector((state) => {
        return Array.isArray(state.modelList.data) ? state.modelList.data : []
    })

    useEffect(() => {
        if (modelList.length == 0) {
            fetchModelList(dispatch)
        }
    }, [item])

    useEffect(() => {
        let firstPackageDataCopy = { ...firstPackageData }
        setPackageData(firstPackageDataCopy)

        setOriginalValues({
            packageId: firstPackageData.packageId,
            name: firstPackageData.name,
            fullWidth: firstPackageData.fullWidth,
            sortIndex: firstPackageData.sortIndex,
            disabled: firstPackageData.disabled,
            currency: firstPackageData.currency,
            discountEndDate: firstPackageData.discountEndDate,
            discountStartDate: firstPackageData.discountStartDate,
            priceDiscount: firstPackageData.priceDiscount,
            hasVipDiscount: firstPackageData.hasVipDiscount,
            metadata: firstPackageData.metadata,
            metadataGold: firstPackageData.metadataGold,
            metadataCl: firstPackageData.metadataCl,
            metadataVip: firstPackageData.metadataVip,
            hasMetadataItems: firstPackageData.hasMetadataItems,
            metadataItems: firstPackageData.metadataItems,
            prices: firstPackageData.prices,
            brlPrice: firstPackageData.brlPrice,
            usdPrice: firstPackageData.usdPrice,
            eurPrice: firstPackageData.eurPrice,
            gbpPrice: firstPackageData.gbpPrice,
            images: firstPackageData.images,
            brlImage: firstPackageData.brlImage,
            usdImage: firstPackageData.usdImage,
            eurImage: firstPackageData.eurImage,
            gbpImage: firstPackageData.gbpImage,
            lifetimeLimit: firstPackageData.lifetimeLimit,
            value: firstPackageData.value,
            startDate: firstPackageData.startDate,
            endDate: firstPackageData.endDate,
            description: firstPackageData.description
        })

        setChangedValues({
            packageId: firstPackageData.packageId,
            name: firstPackageData.name,
            fullWidth: firstPackageData.fullWidth,
            sortIndex: firstPackageData.sortIndex,
            disabled: firstPackageData.disabled,
            currency: firstPackageData.currency,
            discountEndDate: firstPackageData.discountEndDate,
            discountStartDate: firstPackageData.discountStartDate,
            priceDiscount: firstPackageData.priceDiscount,
            hasVipDiscount: firstPackageData.hasVipDiscount,
            metadata: firstPackageData.metadata,
            metadataGold: firstPackageData.metadataGold,
            metadataCl: firstPackageData.metadataCl,
            metadataVip: firstPackageData.metadataVip,
            hasMetadataItems: firstPackageData.hasMetadataItems,
            metadataItems: firstPackageData.metadataItems,
            prices: firstPackageData.prices,
            brlPrice: firstPackageData.brlPrice,
            usdPrice: firstPackageData.usdPrice,
            eurPrice: firstPackageData.eurPrice,
            gbpPrice: firstPackageData.gbpPrice,
            images: firstPackageData.images,
            brlImage: firstPackageData.brlImage,
            usdImage: firstPackageData.usdImage,
            eurImage: firstPackageData.eurImage,
            gbpImage: firstPackageData.gbpImage,
            lifetimeLimit: firstPackageData.lifetimeLimit,
            value: firstPackageData.value,
            startDate: firstPackageData.startDate,
            endDate: firstPackageData.endDate,
            description: firstPackageData.description

        })

    }, [firstPackageData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({})
        setInputValue("")
        setModelItems([])
        setSelectInput('')
        setGoldInput('')
        setTokensInput('')
        setConfigInput('')
        setModelIsValid(false)
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name == 'Items') {
            if (changedValues.metadataItems.length != 0) {
                let tempModelItems = []
                for (const item of changedValues.metadataItems) {
                    let i = 0;
                    while (item.model_id != modelList[i].value) {
                        i++;
                    }
                    tempModelItems.push({
                        modelId: item.model_id,
                        modelName: modelList[i].label,
                        modelIcon: modelList[i].icon,
                        gold: item.priceGold,
                        tokens: item.priceTokens ?? '',
                        config: item.config ?? ''
                    })
                }
                setModelItems([...tempModelItems])
            }
            else {
                setModelItems([])
            }
        }
        if (item.name == 'Início Desconto' || item.name == 'Término Desconto' || item.name == 'Data de Entrada' || item.name == 'Data de Saída') {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(moment(item.value, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'))
            handleOpenModal()
        }
        else if (item.name != "ID Pacote") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let packageDataCopy = { ...packageData, tableProperties: [...packageData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '') {
            for (let i = 0; i < packageData.tableProperties.length; i++) {
                if (packageData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    packageDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    packageDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setPackageData(packageDataCopy);
        }

        if (inputValue != '' && (modalTitle == 'Término Desconto' || modalTitle == 'Início Desconto' || modalTitle == 'Data de Entrada' || modalTitle == 'Data de Saída')) {
            for (let i = 0; i < packageData.tableProperties.length; i++) {
                if (packageData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss')) {
                    packageDataCopy.tableProperties[i] = { name: currentItem.name, value: moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss'), key: currentItem.key };
                    packageDataCopy[currentItem.key] = moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                    changedValuesCopy[currentItem.key] = moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                }
            }
            setChangedValues(changedValuesCopy)
            setPackageData(packageDataCopy);
        }

        if (inputValue == '' && (modalTitle == 'Término Desconto' || modalTitle == 'Início Desconto' || modalTitle == 'Limite')) {
            for (let i = 0; i < packageData.tableProperties.length; i++) {
                if (packageData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    packageDataCopy.tableProperties[i] = { name: currentItem.name, value: "-", key: currentItem.key };
                    packageDataCopy[currentItem.key] = "-";
                    changedValuesCopy[currentItem.key] = "-";
                }
            }
            setChangedValues(changedValuesCopy)
            setPackageData(packageDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setPackageData(firstPackageData)
    }

    const dispatch = useDispatch()

    const handleFileSubmit = async (e) => {
        try {
            const file = e.target.files[0];
            let language = modalTitle.replace(/^Imagem\s+/, "")
            await savePackageImg(item.packageId, file, file.name.trim().replace(/\s+/g, ""), language);
            await fetchSelectedPackageDetails(dispatch, { packageId: item.packageId })
            toast.success('Imagem Salva com Sucesso!');
        }
        catch {
            toast.error('Erro ao Salvar Imagem!');
        }
        handleCloseModal()
    }

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);


            const propertyApi = {
                name: 'name',
                fullWidth: 'fullWidth',
                sortIndex: 'sortIndex',
                disabled: 'disabled',
                currency: 'currency',
                discountEndDate: 'discountEndDate',
                discountStartDate: 'discountStartDate',
                priceDiscount: 'priceDiscount',
                hasVipDiscount: 'hasVipDiscount',
                value: 'value',
                lifetimeLimit: 'lifetimeLimit',
                startDate: 'startDate',
                endDate: 'endDate',
                description: 'description',

                metadataGold: 'metadataGold',
                metadataCl: 'metadataCl',
                metadataVip: 'metadataVip',

                prices: 'prices',
                brlPrice: 'brlPrice',
                usdPrice: 'usdPrice',
                eurPrice: 'eurPrice',
                gbpPrice: 'gbpPrice',
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            changes['prices'] = { ...changedValues['prices'] }

            const keysToRemove = ['brlPrice', 'usdPrice', 'eurPrice', 'gbpPrice'];

            if (changes['brlPrice'] || changes['usdPrice'] || changes['eurPrice'] || changes['gbpPrice']) {
                if (changes['brlPrice']) {
                    changes['prices'] = { ...changes['prices'], BRL: Number(changes['brlPrice'])*100 }
                }
                if (changes['usdPrice']) {
                    changes['prices'] = { ...changes['prices'], USD: Number(changes['usdPrice'])*100 }
                }
                if (changes['eurPrice']) {
                    changes['prices'] = { ...changes['prices'], EUR: Number(changes['eurPrice'])*100 }
                }
                if (changes['gbpPrice']) {
                    changes['prices'] = { ...changes['prices'], GBP: Number(changes['gbpPrice'])*100 }
                }
            }
            else {
                keysToRemove.push('prices')
            }

            changes['value'] = true

            changes = Object.fromEntries(
                Object.entries(changes).filter(([key]) => !keysToRemove.includes(key))
            );


            if (changes['fullWidth']) {
                changes['fullWidth'] == 'Sim' ? changes['fullWidth'] = true : changes['fullWidth'] = false
            }

            if (changes['disabled']) {
                changes['disabled'] == 'Sim' ? changes['disabled'] = true : changes['disabled'] = false
            }

            if (changes['hasVipDiscount']) {
                changes['hasVipDiscount'] == 'Sim' ? changes['hasVipDiscount'] = true : changes['hasVipDiscount'] = false
            }

            if (changes['discountStartDate']) {
                if (changes['discountStartDate'] == '-') {
                    changes['discountStartDate'] = null
                }
                else {
                    changes['discountStartDate'] = moment(changes['discountStartDate'], 'DD/MM/YYYY HH:mm:ss').utc().format();
                }
            }

            if (changes['discountEndDate']) {
                if (changes['discountEndDate'] == '-') {
                    changes['discountEndDate'] = null
                }
                else {
                    changes['discountEndDate'] = moment(changes['discountEndDate'], 'DD/MM/YYYY HH:mm:ss').utc().format();
                }
            }

            if (changes['startDate']) {
                changes['startDate'] = moment(changes['startDate'], 'DD/MM/YYYY HH:mm:ss').utc().format();
            }

            if (changes['endDate']) {
                changes['endDate'] = moment(changes['endDate'], 'DD/MM/YYYY HH:mm:ss').utc().format();
            }

            if (changes['lifetimeLimit'] == '-') {
                changes['lifetimeLimit'] = null
            }

            await savePackage(item.packageId, changes)

            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedPackageDetails(dispatch, { packageId: item.packageId });
            toast.success('Dados salvos com Sucesso!')
        }
        catch (ex) {
            console.log(ex)
            toast.error('Erro ao salvar!')
        }
    }

    const handleDeleteModel = async (tableItem) => {
        try {
            let modelArr = [...modelItems];


            modelItems.map((item, i) => {
                if (item.modelId == tableItem.modelId) {
                    modelArr.splice(modelArr.indexOf(item), 1);
                }
            })
            console.log(modelArr)
            setModelItems(modelArr)
            toast.success('Item Removido com Sucesso')
        }
        catch {
            toast.error('Erro ao Remover Item')
        }

    }

    const handleEditSelectInput = (option) => {
        setSelectInput(option)
    }

    useEffect(() => {
        if (selectInput != '' && goldInput != '' && tokensInput != '' && configInput != '') {
            setModelIsValid(true)
        }
        else {
            setModelIsValid(false)
        }
    }, [selectInput, goldInput, tokensInput, configInput])

    const handleAddItem = () => {
        let modelId = selectInput.value;
        let contains = false
        let tempModelItems = [...modelItems]
        for (const item of modelItems) {
            if (modelId == item.modelId) {
                contains = true
            }
        }
        if (!contains) {
            tempModelItems.push({
                modelId: modelId,
                modelName: selectInput.label,
                modelIcon: selectInput.icon,
                gold: goldInput,
                tokens: tokensInput,
                config: configInput
            })

            setModelItems([...tempModelItems])

            toast.success('Item Adicionado com Sucesso!')
        }
        else {
            toast.error('Item já Adicionado!')
        }

        setSelectInput('')
        setGoldInput('')
        setTokensInput('')
        setConfigInput('')
    }

    const handleItemsSubmit = async () => {
        try {
            let tempArrToSave = []
            for (const modelItem of modelItems) {
                tempArrToSave.push({
                    model_id: modelItem.modelId,
                    priceGold: modelItem.gold,
                    priceTokens: modelItem.tokens,
                    config: modelItem.config
                })
            }

            await savePackageItems(item.packageId, tempArrToSave)
            await fetchSelectedPackageDetails(dispatch, { packageId: item.packageId })
            toast.success('Itens Salvos com Sucesso')
        } catch (ex) {
            console.log(ex)
            toast.error("Erro ao Salvar Itens")
        }
        handleCloseModal()

    }

    const tabs = [
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>

                    <BasicTable
                        onSelectRow={handleSelectRow}
                        headers={packagesHeaders}
                        items={packageData.tableProperties}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                        height="55vh"
                    />
                    {verifyPackagesManage && <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        {(modalTitle != "Imagem BRL" && modalTitle != "Imagem USD" && modalTitle != "Imagem EUR" && modalTitle != "Imagem GBP") ?
                            (modalTitle != "Items") ?
                                <div className={style.modalContentArea}>
                                    <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                                    {
                                        (modalTitle != 'Moeda') ?
                                            (modalTitle != 'Início Desconto' && modalTitle != 'Término Desconto' && modalTitle != 'Data de Entrada' && modalTitle != 'Data de Saída') ?
                                                (modalTitle != 'Ocupa linha inteira' && modalTitle != 'Desabilitado' && modalTitle != 'Desconto VIP') ?
                                                    (modalTitle != 'Nome' && modalTitle != 'Descrição') ?
                                                        <input className={style.modalInput} min={0} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                                        : <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />

                                                    :
                                                    <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                                        <option key={'Sim'} value={'Sim'}>Sim</option>
                                                        <option key={'Não'} value={'Não'}>Não</option>
                                                    </select>
                                                :
                                                <input value={inputValue} onChange={handleOnChangeInput} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                                            :
                                            <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                                <option key={'brl'} value={'brl'}>brl</option>
                                                <option key={'usd'} value={'usd'}>usd</option>
                                                <option key={'eur'} value={'eur'}>eur</option>
                                                <option key={'gbp'} value={'gbp'}>gbp</option>
                                            </select>


                                    }
                                    <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                                </div>
                                :
                                <div className={style.modalTableContent}>
                                    <div className={style.modalTableContentTop}>
                                        <div className={style.modalTableContentTopInputs}>
                                            <div className={style.modalTableContentTopSelect}>
                                                <span className={style.modalLabel}>{`Modelo`}</span>
                                                <Select
                                                    classNamePrefix="select"
                                                    onChange={handleEditSelectInput}
                                                    value={selectInput}
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={false}
                                                    isRtl={false}
                                                    isSearchable={true}
                                                    name="categories"
                                                    options={modelList}
                                                    styles={customStyles}
                                                    placeholder={"Adicionar Modelo"}
                                                />
                                            </div>
                                            <div className={style.modalTableContentTopInput}>
                                                <span className={style.modalLabel}>{`Moedas`}</span>
                                                <input style={{ margin: 0 }} className={style.modalInput} min={0} type="number" placeholder={`Moedas`} value={goldInput} onChange={(e) => setGoldInput(e.target.value)} />
                                            </div>
                                            <div className={style.modalTableContentTopInput}>
                                                <span className={style.modalLabel}>{`Fichas`}</span>
                                                <input style={{ margin: 0 }} className={style.modalInput} min={0} type="number" placeholder={`Fichas`} value={tokensInput} onChange={(e) => setTokensInput(e.target.value)} />
                                            </div>


                                        </div>

                                    </div>
                                    <div className={style.modalTableContentTopBtn}>
                                        <div className={style.modalTableContentTopInput}>
                                            <span className={style.modalLabel}>{`Configuração`}</span>
                                            <input style={{ margin: 0 }} className={style.modalInput} type="text" placeholder={`Configuração`} value={configInput} onChange={(e) => setConfigInput(e.target.value)} />
                                        </div>
                                        <div style={{paddingTop: '15px'}}>
                                            <Button disabled={!modelIsValid} text="Adicionar Item" onClick={handleAddItem} color="cyan" />
                                        </div>
                                        
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        <BasicTable
                                            onClickBtnItemInfo={'Excluir'}
                                            hasButton={true}
                                            onClickBtnItem={handleDeleteModel}
                                            onClickBtnItemHeader={'Delete'}
                                            items={modelItems}
                                            headers={modelItemsHeaders}
                                            height='30vh'
                                        />
                                    </div>
                                    <button className={style.modalButton} onClick={handleItemsSubmit}>Salvar</button>
                                </div>
                            :
                            <div className={style.modalImgContent}>
                                <img className={style.modalImg} src={`${inputValue}`} />
                                <span className={style.modalImgLabel}>{`Nova ${modalTitle}`}</span>
                                <input className={style.inputFile} type='file' accept=".png" onChange={handleFileSubmit} />

                            </div>}
                    </Modal>}
                </>
            )
        }
    ]

    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{item.packageName}</b>
                            <p>{item.packageId}</p>
                        </div>
                    </div>
                )}
            />
        </div>
    )
}
