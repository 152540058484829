import moment from "moment"
import { Paths } from "paths/Paths"

class SpaceCategoryEntry {
    constructor({ entryId, spaceId, spaceName, spaceIcon, ownerId, ownerFirstName, ownerLastName, ownerNameInstance, ownerIcon, startDate, endDate }) {
        this.entryId = entryId
        this.spaceId = spaceId
        this.spaceName = spaceName
        this.spaceIcon = spaceIcon
        this.ownerId = ownerId
        this.ownerFirstName = ownerFirstName
        this.ownerLastName = ownerLastName
        this.ownerNameInstance = ownerNameInstance
        this.ownerIcon = ownerIcon
        this.startDate = startDate
        this.endDate = endDate
    }

    get() {
        return {
            entryId: this.entryId,
            spaceId: this.spaceId,
            ownerName: this.ownerFullName,
            ownerId: this.ownerId,
            ownerIcon: this.ownerIcon,
            spaceName: this.spaceName,
            spaceIcon: Paths.getSpaceThumbnailURL(this.spaceIcon),
            startDate: this.startDate ? moment(this.startDate).format('DD/MM/YYYY') + " " + moment(this.startDate).format('HH:mm:ss') : null,
            endDate: this.endDate ? moment(this.endDate).format('DD/MM/YYYY') + " " + moment(this.endDate).format('HH:mm:ss') : null,
        }
    }

    get ownerFullName() {
        function ordinal(nameInstance) {
            const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
            if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                return nameInstance + "th";
            else
                return nameInstance + "" + ends[nameInstance % 10];
        }
        if (this.ownerNameInstance > 1) return this.ownerFirstName + " " + this.ownerLastName + " the " + ordinal(this.ownerNameInstance);
        else if (this.ownerFirstName === null || this.ownerLastName === null) return "Avatar Apagado"
        else return this.ownerFirstName + " " + this.ownerLastName;
    }
}

export default SpaceCategoryEntry