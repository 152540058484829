import moment from 'moment'
import miniIcon from '../../../img/miniIcon.webp'

class Email {
    constructor({ date, emailId, senderId, userSenderFirstName, userSenderLastName, receiverId, userReceiverFirstName, userReceiverLastName, emailType, emailStatus, emailSubject, isDelletedSender, isDelletedReceiver, senderType, receiverType, avatarReceiverLastName, avatarReceiverFirstName, avatarReceiverInstance, avatarSenderLastName, avatarSenderFirstName, avatarSenderInstance, senderIcon, receiverIcon }) {
        this.date = date
        this.emailId = emailId
        this.senderId = senderId
        this.userSenderFirstName = userSenderFirstName
        this.userSenderLastName = userSenderLastName
        this.receiverId = receiverId
        this.userReceiverFirstName = userReceiverFirstName
        this.userReceiverLastName = userReceiverLastName
        this.emailType = emailType
        this.emailStatus = emailStatus
        this.emailSubject = emailSubject
        this.isDelletedSender = isDelletedSender
        this.isDelletedReceiver = isDelletedReceiver
        this.receiverType = receiverType
        this.senderType = senderType
        this.avatarReceiverLastName = avatarReceiverLastName
        this.avatarReceiverFirstName = avatarReceiverFirstName
        this.avatarReceiverInstance = avatarReceiverInstance
        this.avatarSenderLastName = avatarSenderLastName
        this.avatarSenderFirstName = avatarSenderFirstName
        this.avatarSenderInstance = avatarSenderInstance
        this.senderIcon = senderIcon
        this.receiverIcon = receiverIcon
    }

    get() {
        return {
            date: moment(this.date).format('DD/MM/YYYY') + " " + moment(this.date).format('HH:mm:ss'),
            emailId: this.emailId,
            smallIdEmail: this.smallId(this.emailId),
            senderId: this.senderId,
            senderIcon: this.icon(this.senderType, this.senderIcon, this.avatarSenderInstance, this.avatarSenderLastName),
            receiverIcon: this.icon(this.receiverType, this.receiverIcon, this.avatarReceiverInstance, this.avatarReceiverLastName),
            userSenderFirstName: this.userSenderFirstName,
            userSenderLastName: this.userSenderLastName,
            senderFullName: this.fullName(this.senderType, this.userSenderFirstName, this.userSenderLastName, this.avatarSenderFirstName, this.avatarSenderLastName, this.avatarSenderInstance),
            receiverId: this.receiverId,
            userReceiverFirstName: this.userReceiverFirstName,
            userReceiverLastName: this.userReceiverLastName,
            receiverFullName: this.fullName(this.receiverType, this.userReceiverFirstName, this.userReceiverLastName, this.avatarReceiverFirstName, this.avatarReceiverLastName, this.avatarReceiverInstance),
            emailType: this.getEmailType(this.emailType),
            emailStatus: this.getEmailStatus(this.emailStatus),
            emailSubject: this.emailSubject,
            isDelletedSender: this.isDelletedSender,
            isDelletedReceiver: this.isDelletedReceiver,
            receiverType: this.receiverType,
            senderType: this.senderType,
            avatarReceiverLastName: this.avatarReceiverLastName,
            avatarReceiverFirstName: this.avatarReceiverFirstName,
            avatarReceiverInstance: this.avatarReceiverInstance,
            avatarSenderLastName: this.avatarSenderLastName,
            avatarSenderFirstName: this.avatarSenderFirstName,
            avatarSenderInstance: this.avatarSenderInstance
        }
    }

    smallId(id) {
        return id?.slice(0, 7)
    }

    fullName(type, userFirstName, userLastName, avatarFirstName, avatarLastName, avatarInstance) {
        if (type === 'S') return 'MiniMania'
        else if (type === 'U') {
            if (userFirstName === null || userLastName === null) return "Usuário Apagado"
            else return `${userFirstName} ${userLastName}`
        }
        else if (type === 'A') {
            function ordinal(nameInstance) {
                const ends = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
                if (((nameInstance % 100) >= 11) && ((nameInstance % 100) <= 13))
                    return nameInstance + "th";
                else
                    return nameInstance + "" + ends[nameInstance % 10];
            }
            if (avatarInstance > 1) return avatarFirstName + " " + avatarLastName + " the " + ordinal(avatarInstance);
            else if (avatarFirstName === null || avatarLastName === null) return "Avatar Apagado"
            else return avatarFirstName + " " + avatarLastName;
        }

    }

    icon(type, iconStr, avatarFirstName, avatarLastName){
        if (type === 'S') return miniIcon
        else if (type === 'A'){
            return (!avatarFirstName || !avatarLastName) ? "https://cdn.minimania.app/content/content-v102/assets/avatars/head/base/unisex/icons/notexture_icon.png" : `https://cdn.minimania.app/avatars/${iconStr}`
        }
        else if (type === 'U') return null
    }

    getEmailType(emailType) {
        const types = {
            S: 'Sistema',
            U: 'Usuário',
            A: 'Avatar'
        }
        return types[emailType]
    }

    getEmailStatus(emailStatus) {
        const stats = {
            R: "Lido",
            N: "Não Lido"
        }

        return stats[emailStatus]
    }
}

export default Email