import React, { useEffect, useState } from 'react';
import { UseTournament } from './tournamentSearch';
import moment from 'moment';
import useUserPermission from 'domains/Permissions/useUserPermission';
import { SMIPermissionsCode } from 'domains/Permissions/SMIPermissionsCode';
import { useDispatch } from 'react-redux';
import BasicTable from 'components/BasicTable';
import Button from 'components/form/Button';
import Tabs from 'components/Tabs';
import Modal from 'components/Modal';
import style from 'styles/UserTabs.module.css'
import styles from 'styles/Search.module.css'
import { fetchSelectedTournamentDetails } from 'store/modules/tournaments/selectedTournamentDetailSlice';
import { toast } from 'react-toastify';
import { deleteTournament, finishTournament, saveTournament } from 'services/tournamentsService';
import { fetchTournaments } from 'store/modules/tournaments/tournamentsSlice';
import { cleanTournamentForDetails } from 'store/modules/tournaments/selectedTournamentSlice';


export default function Details({ item }) {
    const verifyMTournamentChange = useUserPermission(SMIPermissionsCode.MOD_TOURNAMENT_CHANGE);

    const missionsHeaders = [
        { title: 'Nome', key: 'name', },
        { title: 'Valor', key: 'value', selectable: true },
    ]

    const firstTournamentData = UseTournament(item);
    const [tournamentData, setTournamentData] = useState(firstTournamentData)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const [modalTitle, setModalTitle] = useState("")
    const [inputValue, setInputValue] = useState("")
    const [handleValueChange, setHandleValueChange] = useState(true)
    const [isDeleteTournament, setIsDeleteTournament] = useState(false);
    const [isFinishTournament, setIsFinishTournament] = useState(false);

    const [originalValues, setOriginalValues] = useState({})
    const [changedValues, setChangedValues] = useState({})

    useEffect(() => {
        let firstTournamentDataCopy = { ...firstTournamentData }
        setTournamentData(firstTournamentDataCopy)

        setOriginalValues({
            name: firstTournamentData.name,
            details: firstTournamentData.details,
            tournamentId: firstTournamentData.tournamentId,
            gameId: firstTournamentData.gameId,
            bountyGold: firstTournamentData.bountyGold,
            bountyTokens: firstTournamentData.bountyTokens,
            groupEntry: firstTournamentData.groupEntry,
            firstPlace: firstTournamentData.firstPlace,
            secondPlace: firstTournamentData.secondPlace,
            thirdPlace: firstTournamentData.thirdPlace,
            endTime: firstTournamentData.endTime,

        })

        setChangedValues({
            name: firstTournamentData.name,
            details: firstTournamentData.details,
            tournamentId: firstTournamentData.tournamentId,
            gameId: firstTournamentData.gameId,
            bountyGold: firstTournamentData.bountyGold,
            bountyTokens: firstTournamentData.bountyTokens,
            groupEntry: firstTournamentData.groupEntry,
            firstPlace: firstTournamentData.firstPlace,
            secondPlace: firstTournamentData.secondPlace,
            thirdPlace: firstTournamentData.thirdPlace,
            endTime: firstTournamentData.endTime,

        })

    }, [firstTournamentData])

    useEffect(() => {
        let originalValuesProps = Object.getOwnPropertyNames(originalValues);
        let changedValuesProps = Object.getOwnPropertyNames(changedValues);

        if (originalValuesProps.length != changedValuesProps.length) {
            setHandleValueChange(false)
            return;
        }

        for (let i = 0; i < originalValuesProps.length; i++) {
            let propName = originalValuesProps[i];

            if (originalValues[propName] !== changedValues[propName]) {
                setHandleValueChange(false)
                return;
            }
        }
        setHandleValueChange(true)

    }, [item, changedValues])

    const handleOpenModal = () => {
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setCurrentItem({});
        setInputValue("");
        setIsModalOpen(false)
    }

    const handleSelectRow = (item) => {
        if (item.name == 'Data de Término') {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(moment(item.value, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'))
            handleOpenModal()
        }
        else if (item.name != "ID Torneio") {
            setCurrentItem(item)
            setModalTitle(item.name)
            setInputValue(item.value)
            handleOpenModal()
        }
    }

    const handleOnChangeInput = (event) => {
        setInputValue(event.target.value)
    }

    const handleSubmit = () => {
        let tournamentDataCopy = { ...tournamentData, tableProperties: [...tournamentData.tableProperties] };
        let changedValuesCopy = { ...changedValues }

        if (inputValue != '' && modalTitle == 'Data de Término') {
            for (let i = 0; i < tournamentData.tableProperties.length; i++) {
                if (tournamentData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss')) {
                    tournamentDataCopy.tableProperties[i] = { name: currentItem.name, value: moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss'), key: currentItem.key };
                    tournamentDataCopy[currentItem.key] = moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                    changedValuesCopy[currentItem.key] = moment(inputValue, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                }
            }
            setChangedValues(changedValuesCopy)
            setTournamentData(tournamentDataCopy);
        }
        if (inputValue != '' && modalTitle != 'Data de Término') {
            for (let i = 0; i < tournamentData.tableProperties.length; i++) {
                if (tournamentData.tableProperties[i].key == currentItem.key && changedValues[currentItem.key] != inputValue) {
                    tournamentDataCopy.tableProperties[i] = { name: currentItem.name, value: inputValue, key: currentItem.key };
                    tournamentDataCopy[currentItem.key] = inputValue;
                    changedValuesCopy[currentItem.key] = inputValue;
                }
            }
            setChangedValues(changedValuesCopy)
            setTournamentData(tournamentDataCopy);
        }
        setCurrentItem({});
        setInputValue("");
        handleCloseModal();

    }

    const handleDiscardChanges = () => {
        setChangedValues({ ...originalValues })
        setTournamentData(firstTournamentData)
    }

    const dispatch = useDispatch()

    const handleSaveChanges = async () => {
        try {
            let originalValuesCopy = { ...originalValues }
            let changedValuesCopy = { ...changedValues }
            let originalValuesProps = Object.getOwnPropertyNames(originalValues);

            const propertyApi = {
                name: 'desc',
                details: 'details',
                gameId: 'gameId',
                bountyGold: 'bountyGold',
                bountyTokens: 'bountyTokens',
                groupEntry: 'groupEntry',
                firstPlace: 'firstPlace',
                secondPlace: 'secondPlace',
                thirdPlace: 'thirdPlace',
                endTime: 'endTime',
            }

            let changes = {}

            for (let i = 0; i < originalValuesProps.length; i++) {
                let propName = originalValuesProps[i];
                if (originalValues[propName] !== changedValues[propName]) {
                    changes[propertyApi[propName]] = changedValues[propName]
                }
            }

            if (changes['firstPlace'] || changes['secondPlace'] || changes['thirdPlace']) {
                changes['tdist_value'] = `${changedValues.firstPlace}:${changedValues.secondPlace}:${changedValues.thirdPlace}`
            }


            if (changes['groupEntry']) {
                changes['groupEntry'] == 'Sim' ? changes['groupEntry'] = true : changes['groupEntry'] = false
            }


            if (changes['startDate']) {
                changes['startDate'] = moment(changes['startDate'], 'DD/MM/YYYY HH:mm:ss').utc().format();
            }
            await saveTournament(item.tournamentId, changes)

            console.log(changedValuesCopy)
            setChangedValues(changedValuesCopy)
            setOriginalValues(changedValuesCopy)

            fetchSelectedTournamentDetails(dispatch, { tournamentId: item.tournamentId });
            toast.success('Dados salvos com Sucesso!')
        }
        catch {
            toast.error('Erro ao salvar!')
        }
    }

    const handleDeleteTournament = async () => {
        try {
            await deleteTournament(item.tournamentId)

            await fetchTournaments(dispatch, {}, 0);
            dispatch(cleanTournamentForDetails())


            handleCloseDeleteTournamentModal()
            toast.success('Torneio Removido com Sucesso!')
        }
        catch {
            toast.error('Erro ao Remover Torneio!')
        }
    }

    const handleFinishTournament = async () => {
        try {
            await finishTournament(item.tournamentId)

            await fetchTournaments(dispatch, {}, 0);
            dispatch(cleanTournamentForDetails())


            handleCloseFinishTournamentModal()
            toast.success('Torneio Finalizado com Sucesso!')
        }
        catch {
            toast.error('Erro ao Finalizar Torneio!')
        }
    }

    const handleOpenDeleteTournamentModal = () => {
        setIsDeleteTournament(true)
    }

    const handleCloseDeleteTournamentModal = () => {
        setIsDeleteTournament(false)
    }

    const handleOpenFinishTournamentModal = () => {
        setIsFinishTournament(true)
    }

    const handleCloseFinishTournamentModal = () => {
        setIsFinishTournament(false)
    }

    const tabs = []

    tabs.push(
        {
            id: 1,
            tabTitle: 'Propriedades',
            content: (
                <>
                    <BasicTable
                        onSelectRow={handleSelectRow}
                        headers={missionsHeaders}
                        items={tournamentData.tableProperties}
                        slotHeader={(handleValueChange) ? "" :
                            <div className={style.header}>
                                <Button text="Salvar" onClick={handleSaveChanges} color="cyan" />
                                <Button text="Descartar" onClick={handleDiscardChanges} color="cyan" />
                            </div>
                        }
                        height='55vh'
                    />
                    {verifyMTournamentChange && <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                    >
                        <div className={style.modalContent}>
                            <span className={style.modalLabel}>{`Novo Valor para ${modalTitle}`}</span>

                            {modalTitle != 'Data de Término' ?
                                (modalTitle != 'Grupo') ?
                                    (modalTitle != 'Prêmio em Moedas' && modalTitle != 'Prêmio em Tokens' && modalTitle != '1º Prêmio' && modalTitle != '2º Prêmio' && modalTitle != '3º Prêmio') ?
                                        <input className={style.modalInput} type="text" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                        : <input className={style.modalInput} type="number" placeholder={`${modalTitle}`} value={inputValue} onChange={handleOnChangeInput} />
                                    :
                                    <select value={inputValue} className={style.select} onChange={handleOnChangeInput}>
                                        <option key={'Sim'} value={'Sim'}>Sim</option>
                                        <option key={'Não'} value={'Não'}>Não</option>
                                    </select>
                                :
                                <input value={inputValue} onChange={handleOnChangeInput} placeholder='' type='datetime-local' className='w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:border-cyan-800' />
                            }

                            <button className={style.modalButton} onClick={handleSubmit}>Alterar</button>
                        </div>
                    </Modal>}
                </>
            )
        }
    )


    return (
        <div className='ring-2 ring-black ring-opacity-10 rounded-lg h-auto p-2'>
            <Tabs
                tabs={tabs}
                slotHeader={(
                    <div className='p-2 text-sm' style={{ display: 'flex', margin: '5px', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <b>{item.name}</b>
                            <p>{item.tournamentId}</p>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: "10px"}}>
                        {<Button text="Excluir" color="cyan" onClick={handleOpenDeleteTournamentModal} />}
                        {<Button text="Finalizar" color="cyan" onClick={handleOpenFinishTournamentModal} />}
                        </div>
                    </div>
                )}
            />
            <Modal
                header={`Atenção!`}
                isOpen={isDeleteTournament}
                onClose={handleCloseDeleteTournamentModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja apagar permanentemente o torneio?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleDeleteTournament} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseDeleteTournamentModal} color="cyan" />
                    </div>
                </div>
            </Modal>


            <Modal
                header={`Atenção!`}
                isOpen={isFinishTournament}
                onClose={handleCloseFinishTournamentModal}
            >
                <div className={styles.modalModLogContainer}>
                    <div className={styles.modalSlotHeaderTitle}>
                        <span className={styles.modalSubtitle}>Deseja finalizar o torneio?</span>
                    </div>
                    <div className={styles.modalSlotHeaderBtn}>
                        <Button disabled={false} text="Sim" onClick={handleFinishTournament} color="cyan" />
                        <Button disabled={false} text="Não" onClick={handleCloseFinishTournamentModal} color="cyan" />
                    </div>
                </div>
            </Modal>
        </div>
    )
}
